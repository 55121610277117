import Action from '../Action';

class Text extends Action {
  constructor () {
    super();
    this.path = 'text';
  }
};

export default new Text();
